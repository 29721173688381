<template>
  <div class="home">
    <TablePage/>
    <!-- <TableForm /> -->
  </div>
</template>

<script>
import TablePage from './table';
// import TableForm from './table_form';

export default {
  name: 'login_page',
  components: {
    TablePage,
    // TableForm,
  },
  created() {
    // this.getListData();
  },
  methods: {},

  data() {
    return {};
  },
};
</script>
