<script>
import Form, { formCreate } from '../../../../../components/form';
import TableForm from './components';

formCreate.component('TableForm', TableForm);
// formCreate.component('Text', Text);

export default {
  extends: Form,
  components: {
    TableForm,
    // Text,
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      option: {
        resetBtn: false,
        submitBtn: false,
      },
      rule: [
        {
          type: 'formHeader',
          props: {
            title: '基本属性',
          },
        },
        {
          type: 'span',
          title: '类型',
          field: 'dataType',
          text: this.formConfig && this.formConfig.dataType,
          props: {
            text: this.formConfig && this.formConfig.dataType,
          },
          children: 'zaaa',
          col: {
            md: { span: 8 },
          },
        },
        {
          type: 'Text',
          title: '模版名称',
          field: 'templateName',
          props: {
            text: this.formConfig && this.formConfig.templateName,
          },
          col: {
            md: { span: 8 },
          },
        },
        {
          type: 'Text',
          title: '登录页标题',
          field: 'indexTitle',
          props: {
            text: this.formConfig && this.formConfig.indexTitle,
          },
          col: {
            md: { span: 8 },
          },
        },
        {
          type: 'span',
          field: 'servicePhone',
          title: '服务热线',
          value: this.formConfig && this.formConfig.servicePhone,
          col: {
            md: { span: 8 },
          },
        },
        {
          type: 'span',
          field: 'clientPhone',
          title: '客服电话',
          value: this.formConfig && this.formConfig.clientPhone,
          col: {
            md: { span: 8 },
          },
        },
        {
          type: 'span',
          field: 'recordNo',
          title: '备案号',
          value: this.formConfig && this.formConfig.recordNo,
          col: {
            md: { span: 8 },
          },
        },
        {
          type: 'span',
          field: 'companyName',
          title: '公司名称',
          value: this.formConfig && this.formConfig.companyName,
          col: {
            md: { span: 8 },
          },
        },
        {
          type: 'span',
          field: 'buttonColour',
          title: '登录按钮颜色',
          value: this.formConfig && this.formConfig.buttonColour,
          col: {
            md: { span: 8 },
          },
        },
        {
          type: 'span',
          field: 'bottomText',
          title: '底部文字',
          props: {
            children: this.formConfig && this.formConfig.bottomText,
          },
          col: {
            md: { span: 16 },
          },
        },
        {
          type: 'formHeader',
          props: {
            title: '图片信息',
          },
        },
        {
          type: 'upload',
          field: 'logoUrl',
          title: '首行企业logo图片',
          value: (this.formConfig && this.formConfig.logoUrl && [this.formConfig.logoUrl]) || [],
          props: {
            disabled: true,
          },
          col: {
            md: { span: 16 },
          },
        },
        {
          type: 'row',
          title: '长中部长广告图片',
          children: [
            {
              type: 'i-col',
              children: [
                {
                  type: 'span',
                  field: 'logoTime',
                  title: '轮播时间（秒）',
                  value: this.formConfig && this.formConfig.logoTime,
                  col: {
                    md: { span: 8 },
                  },
                },
                {
                  type: 'upload',
                  field: 'bannerItems',
                  title: ' ',
                  value: (this.formConfig && this.formConfig.items && this.formConfig.items.filter((v) => v.dataType === '1').map((v) => v.detailUrl)) || [],
                  props: {
                    disabled: true,
                  },
                  col: {
                    md: { span: 24 },
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'formHeader',
          props: {
            title: '二维码信息',
          },
        },
        {
          type: 'group',
          field: 'barCodeItems',
          value: this.formConfig && this.formConfig.items && this.formConfig.items.filter((v) => v.dataType === '2'),
          props: {
            min: 1,
            expand: 1,
            rules: [
              {
                type: 'span',
                field: 'name',
                title: '名称',
                col: {
                  md: { span: 8 },
                },
              },
              {
                type: 'upload',
                field: 'detailUrl',
                title: ' ',
                props: {
                  disabled: true,
                },
                col: {
                  md: { span: 24 },
                },
              },
            ],
          },
        },
      ],
    };
  },
  mounted() {
    const len = Object.keys(this.formConfig).length;

    if (len === 0 || (len > 0 && this.formConfig.functionLevel === '0')) {
      this.hiddenFields(true, ['parentCode']);
    }
  },
  methods: {
    changeFn(val) {
      this.hiddenFields(val === '0', ['parentCode']);
    },
  },
};
</script>
