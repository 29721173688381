<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';
import TableForm from './components';
import Storage from '../../../../../utils/storage';

formCreate.component('TableForm', TableForm);

/**
 * 类型
 */
const DataTypeOptions = [
  {
    value: '1',
    label: '后台',
  },
  {
    value: '2',
    label: '小程序',
  },
];

export default {
  extends: Form,
  components: {
    TableForm,
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      barCodeItems: [
        {
          name: '',
          detailUrl: '',
        },
      ],
      barCodeRule: [], // 二维码数据
      initRule: [
        {
          type: 'formHeader',
          props: {
            title: '基本属性',
          },
        },
        {
          type: 'select',
          title: '类型',
          field: 'dataType',
          props: {
            placeholder: '请选择类型',
          },
          value: this.formConfig && this.formConfig.dataType,
          options: DataTypeOptions,
          validate: [
            {
              required: true,
              message: '请选择类型',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          title: '模版名称',
          field: 'templateName',
          props: {
            placeholder: '请输入模版名称',
            clearable: true,
          },
          value: this.formConfig && this.formConfig.templateName,
          validate: [
            {
              required: true,
              trigger: 'blur',
              message: '请输入模版名称',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          title: '登录页标题',
          field: 'indexTitle',
          value: this.formConfig && this.formConfig.indexTitle,
          props: {
            placeholder: '请输入登录标题',
            clearable: true,
          },
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'servicePhone',
          title: '服务热线',
          value: this.formConfig && this.formConfig.servicePhone,
          refresh: true,
          props: {
            placeholder: '请输入服务热线',
            disabled: false,
            // loading: true,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'blur',
              pattern: /^\d+(\.\d+)?$/,
              message: '服务热线只能输入数字',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'clientPhone',
          title: '客服电话',
          value: this.formConfig && this.formConfig.clientPhone,
          props: {
            placeholder: '请输入客服电话',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'blur',
              pattern: /^\d+(\.\d+)?$/,
              message: '客户电话只能输入数字',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'recordNo',
          title: '备案号',
          value: this.formConfig && this.formConfig.recordNo,
          props: {
            placeholder: '请输入备案号',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'companyName',
          title: '公司名称',
          value: this.formConfig && this.formConfig.companyName,
          props: {
            placeholder: '请输入公司名称',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'ColorPicker',
          field: 'buttonColour',
          title: '登录按钮颜色',
          value: this.formConfig && this.formConfig.buttonColour,
          validate: [
            {
              required: true,
              message: '请输入登录按钮颜色',
            },
          ],
          props: {
            placeholder: '请输入登录按钮颜色',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'bottomText',
          title: '底部文字',
          value: this.formConfig && this.formConfig.bottomText,
          props: {
            placeholder: '请输入底部文字',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          col: {
            md: { span: 16 },
          },
        },
        {
          type: 'formHeader',
          props: {
            title: '图片信息',
          },
        },
        {
          type: 'upload',
          field: 'logoUrl',
          title: '首行企业logo图片',
          value: (this.formConfig && this.formConfig.logoUrl && [this.formConfig.logoUrl]) || [],
          props: {
            accept: 'image/*',
            multiple: false,
            limit: 1,
            action: '',
            limitSize: 'mdm_login_logo_size',
            limitAccept: 'mdm_login_logo_type',
          },
          col: {
            md: { span: 16 },
          },
        },
        {
          type: 'row',
          title: '长中部长广告图片',
          children: [
            {
              type: 'col',
              children: [
                {
                  type: 'input',
                  field: 'logoTime',
                  title: '轮播时间（秒）',
                  value: this.formConfig && this.formConfig.logoTime,
                  props: {
                    placeholder: '请输入轮播时间',
                    disabled: this.formConfig.code === 'view',
                  },
                  validate: [
                    {
                      trigger: 'blur',
                      required: true,
                      message: '轮播时间必填',
                    },
                  ],
                  col: {
                    md: { span: 8 },
                  },
                },
                {
                  type: 'upload',
                  field: 'bannerItems',
                  title: ' ',
                  value:
                    (this.formConfig && this.formConfig.items && this.formConfig.items.filter((v) => v.dataType === '1').map((v) => v.detailUrl)) || [],
                  props: {
                    limit: 50,
                    accept: 'image/*',
                    multiple: true,
                    type: 'select',
                    name: 'file',
                    previewMask: false,
                    uploadType: 'image',
                    action: '/upload/uploadController/uploadOssList',
                    disabled: this.formConfig.code === 'view',
                    headers: {
                      loginUserToken: Storage.l.get('TokenKey'),
                    },
                    onSuccess: ({ result }) => {
                      const rule = this.getRule('bannerItems');
                      const fileData = result;
                      const successList = fileData.map((v) => ({
                        dataType: '1',
                        detailUrl: v.url,
                        enableStatus: '1',
                        name: '',
                      }));
                      if (!rule.props.fileList) {
                        rule.props.fileList = [];
                      }
                      if (!rule.value) {
                        rule.value = [];
                      }
                      rule.value.push(successList.map((v) => v.detailUrl));
                      rule.props.fileList = rule.props.fileList.concat(successList);
                    },
                  },
                  col: {
                    md: { span: 24 },
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'formHeader',
          props: {
            title: '二维码信息',
          },
        },

        // {
        //   type: 'group',
        //   field: 'barCodeItems',
        //   value: this.formConfig && this.formConfig.items && this.formConfig.items.filter((v) => v.dataType === '2'),
        //   on: {
        //     'detail-url-props-onSuccess': (a, b, c) => {
        //       console.log(a, b, c);
        //     },
        //   },
        //   props: {
        //     min: 1,
        //     expand: 1,
        //     rules: [
        //       {
        //         type: 'input',
        //         field: 'name',
        //         title: '名称',
        //         props: {
        //           placeholder: '请输入二维码名称',
        //           disabled: false,
        //           clearable: true, // 是否显示清空按钮
        //         },
        //         col: {
        //           md: { span: 8 },
        //         },
        //       },
        //       {
        //         type: 'upload',
        //         field: 'detailUrl',
        //         title: ' ',
        //         emit: ['onSuccess'],
        //         props: {
        //           multiple: false,
        //           limit: 1,
        //           type: 'select',
        //           name: 'file',
        //           previewMask: false,
        //           uploadType: 'image',
        //           value: [],
        //           action: '/upload/uploadController/uploadOssList',
        //           headers: {
        //             loginUserToken: Storage.l.get('TokenKey'),
        //           },
        //           onSuccess: ({ result }) => {
        //             // console.log(a);
        //             console.log(this.getRule('barCodeItems'));
        //             console.log(this.fApi.el('barCodeItems'));
        //             console.log(this.rule);
        //             console.log(this.value);
        //             // console.log(result);
        //             // const rule = this.elForm.$children[0].$children[1].$children[0].getRules();
        //             console.log(result);
        //             // const fileData = result;
        //             // const successList = fileData.map((v) => ({
        //             //   dataType: '2',
        //             //   detailUrl: v.url,
        //             //   enableStatus: '1',
        //             //   name: '',
        //             // }
        //             // ));
        //             // rule.data = successList[0].detailUrl;
        //             // rule.props.fileList = successList;
        //           },
        //         },
        //         col: {
        //           md: { span: 24 },
        //         },
        //       },
        //     ],
        //   },
        // },
      ],
    };
  },
  created() {
    this.getBarCodeItems();
  },
  mounted() {
    const barCodeItems = this.formConfig.items.filter((v) => v.dataType === '2');
    this.barCodeItems = [];

    barCodeItems.forEach((item, index) => {
      this.addBarCodeItem();
      this.setValue({
        [`group_barCodeItems_${index}_name`]: item.name,
        [`group_barCodeItems_${index}_detailUrl`]: item.detailUrl,
      });
    });
  },
  methods: {
    // 生成barcodeItems
    getBarCodeItems() {
      let barCodeItemArr = [];
      // this.barCodeItems.forEach((v, k) => {
      //   console.log(this.getBarCodeItem(k));
      //   barCodeItemArr = [...barCodeItemArr, ...this.getBarCodeItem(k)];
      // });
      this.barCodeItems.forEach((v, k) => {
        const index = k;
        barCodeItemArr = barCodeItemArr.concat(this.getBarCodeItem(index));
      });
      this.barCodeRule = barCodeItemArr.map((item1) => Object.assign(
        item1,
        this.barCodeRule.find((item2) => item2 && item1.field === item2.field),
      ));
      this.rule = [...this.initRule, ...this.barCodeRule];
    },
    // barcodeItems的每一项
    getBarCodeItem(index) {
      let barCodeitem = [];
      if (index === this.barCodeItems.length - 1) {
        barCodeitem = [
          {
            type: 'input',
            field: `group_barCodeItems_${index}_name`,
            title: '名称',
            props: {
              placeholder: '请输入二维码名称',
              clearable: true, // 是否显示清空按钮
            },
            validate: [
              {
                trigger: 'blur',
                required: true,
                message: '二维码名称必填',
              },
            ],
            col: {
              md: { span: 13 },
            },
          },
          {
            type: 'formButton',
            props: {
              circle: true,
              icon: 'el-icon-plus',
            },
            on: {
              click: () => {
                this.addBarCodeItem();
              },
            },
            col: {
              md: {
                span: 1,
                offset: 8,
              },
            },
          },
          {
            type: 'formButton',
            props: {
              circle: true,
              type: 'danger',
              icon: 'el-icon-minus',
            },
            on: {
              click: () => {
                this.delBarCodeItem(index);
              },
            },
            col: {
              md: {
                span: 1,
              },
            },
          },
          {
            type: 'upload',
            field: `group_barCodeItems_${index}_detailUrl`,
            title: '图片',
            value: [],
            props: {
              multiple: false,
              accept: 'image/*',
              limit: 1,
              action: '',
            },
            col: {
              md: { span: 13 },
            },
          },
        ];
      } else {
        barCodeitem = [
          {
            type: 'input',
            field: `group_barCodeItems_${index}_name`,
            title: '名称',
            props: {
              placeholder: '请输入二维码名称',
              clearable: true, // 是否显示清空按钮
            },
            col: {
              md: { span: 13 },
            },
          },
          {
            type: 'upload',
            field: `group_barCodeItems_${index}_detailUrl`,
            title: '图片',
            value: [],
            props: {
              accept: 'image/*',
              multiple: false,
              limit: 1,
              action: '',
            },
            col: {
              md: { span: 13 },
            },
          },
        ];
      }

      return barCodeitem;
    },

    // 新增一行
    addBarCodeItem() {
      const item = {
        name: '',
        detailUrl: '',
      };
      this.barCodeItems.push(item);
      this.getBarCodeItems();
      this.reload(this.rule);
    },
    // 删除一行
    delBarCodeItem(index) {
      console.log(index);
      this.barCodeItems.splice(index, 1);
      this.getBarCodeItems();
      this.reload(this.rule);
    },

    // 处理提交数据
    dealSubmitData(data) {
      const keys = Object.keys(data);
      const positionListKeys = [];
      keys.forEach((item) => {
        if (item.includes('group_barCodeItems_')) {
          positionListKeys.push(item);
        }
      });
      const positionListKeysArray = [];

      positionListKeys.forEach((item) => {
        const itemKyes = item.split('_');
        // const tempPositionList = {};
        // tempPositionList[itemKyes[3]][itemKyes[3]] = data[item];
        console.log(itemKyes);
        console.log(item);
        console.log(Number(itemKyes[2]));
        positionListKeysArray[Number(itemKyes[2])] = {
          ...positionListKeysArray[Number(itemKyes[2])],
          [String(itemKyes[3])]: data[item],
        };
      });
      return positionListKeysArray;
    },

    // 提交
    submit() {
      const formData = this.getFormData();
      console.log(formData);
      if (formData) {
        const url = '/mdm/sys/mdmIndexConfigController/saveOrUpdate';
        let params = formData;
        if (this.formConfig && this.formConfig.code === 'edit') {
          params = Object.assign(this.formConfig, formData, { logoUrl: Array.isArray(params.logoUrl) ? params.logoUrl[0] : params.logoUrl });
        } else {
          params.logoUrl = Array.isArray(params.logoUrl) ? params.logoUrl[0] : params.logoUrl;
        }
        const { bannerItems, ...otherData } = params;
        const barCodeItems = this.dealSubmitData(formData);
        const items = [];
        if (barCodeItems) {
          barCodeItems.forEach((v) => {
            items.push({
              dataType: '2',
              detailUrl: Array.isArray(v.detailUrl) ? v.detailUrl[0] : v.detailUrl,
              enableStatus: '1',
              name: v.name,
            });
          });
        }
        if (bannerItems) {
          bannerItems.forEach((v) => {
            items.push({
              dataType: '1',
              detailUrl: Array.isArray(v) ? v[0] : v,
              enableStatus: '1',
              name: '',
            });
          });
        }
        params = {
          ...otherData,
          items,
        };
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            // this.closeModal();
            // this.getList();
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-form-item .el-form-item__content .el-input, .el-form-item .el-form-item__content .el-select {
  height: auto !important;
}
</style>
