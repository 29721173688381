<script>
import TablePage from '../../../../../components/table_page';
import configs from '../data';
import Form from '../form';
import Modal from '../../../../../components/modal';
import FormView from '../form/view.vue';

export default {
  extends: TablePage,
  components: {
    Modal,
    Form,
    FormView,
  },
  data() {
    return {
      formName: 'Form',
      requestUrl: '/mdm/sys/mdmIndexConfigController/list',
      configs,
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  created() {

  },
  methods: {
    modalClick({ val, row }) {
      this.formConfig = {};
      this.formName = 'Form';
      console.log(val);
      // 弹框新增不再使用新页面
      // if (val.code === 'add') {
      //   this.$router.push({
      //     path: '/config_center/login_page_manage/add',
      //   });
      //   return;
      // }
      // if(row) {
      //   selection = [row.id]
      // }
      if (val.code === 'enable' || val.code === 'row-enable') {
        // let selection = [];
        if (val.code === 'row-enable') {
          this.selection = [row.id];
        } else if (!this.selection || this.selection.length < 1) {
          this.$message({
            type: 'error',
            message: '请至少选择一条数据',
          });
          return;
        }
        const data = {
          params: { enableStatus: '009', ids: this.selection },
          url: '/mdm/sys/mdmIndexConfigController/stopOrOpen',
          title: '此操作将启用配置, 是否继续?',
          msg: '启用',
        };
        this.messageBox(data);
        return;
      }
      if (val.code === 'disable' || val.code === 'row-disable') {
        if (val.code === 'row-disable') {
          this.selection = [row.id];
        } else if (!this.selection || this.selection.length < 1) {
          this.$message({
            type: 'error',
            message: '请至少选择一条数据',
          });
          return;
        }
        const data = {
          params: { enableStatus: '003', ids: this.selection },
          url: '/mdm/sys/mdmIndexConfigController/stopOrOpen',
          title: '此操作将禁用配置, 是否继续?',
          msg: '禁用',
        };
        this.messageBox(data);
        return;
      }
      if (val.code === 'view') {
        this.formConfig = {
          ...val,
          ...row,
        };
        this.openModal();
        return;
      }
      if (val.code === 'log') {
        this.$message({ type: 'info', message: '建设中...' });
        return;
      }
      if (val.code === 'edit') {
        this.formConfig = {
          ...val,
          ...row,
        };
        this.modalConfig.title = '编辑';
      }
      if (val.code === 'add') {
        this.formConfig = {
          ...val,
          ...row,
        };
        this.modalConfig.title = '新增';
      }
      // if (row && row.id) {
      //   console.log(val);
      //   this.formConfig = { id: row.id };
      // }

      this.openModal();
    },
    renderDataType({ cellValue }) {
      return `${cellValue}aa`;
    },
    deleteClick() {
      // 只做表格按钮删除
      if (!this.selection || this.selection.length < 1) {
        this.$message({
          type: 'error',
          message: '请至少选择一条数据',
        });
        return;
      }
      const data = {
        params: this.selection,
        url: '/mdm/sys/mdmIndexConfigController/delete',
        title: '此操作将永久删除配置, 是否继续?',
        msg: '删除',
      };
      this.messageBox(data);
    },
  },
};
</script>
