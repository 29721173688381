import { VXETable } from 'vxe-table';
// import request from '../../../../utils/request';
VXETable.renderer.add('cellLink', {
  // 默认显示模板
  renderDefault(h, renderOpts, params) {
    const {
      row, column,
    } = params;
    const { events } = renderOpts;
    return [
      <a class="my-link" onClick={() => events.click(params) }>{row[column.property]}</a>,
    ];
  },
});
/**
 * 状态选项
 */
const StatusOptions = [
  {
    value: '009',
    label: '启用',
  },
  {
    value: '003',
    label: '禁用',
  },
];
const DataTypeOptions = [
  {
    value: '1',
    label: '后台',
  },
  {
    value: '2',
    label: '小程序',
  },
];
export default {
  formConfig: {
    titleWidth: 100,
    titleAlign: 'right',
    statusOptions: StatusOptions,
    dataTypeOptions: DataTypeOptions,
    item: [
      {
        field: 'templateName',
        search: 'true',
        title: '模版名称',
        type: 'input',
      },
      {
        field: 'enableStatus',
        search: 'true',
        title: '生效状态',
        type: 'select',
        options: StatusOptions,
      },
    ],
  },
  toolBarConfig: [
    {
      type: 'button',
      // buttonType: 'primary',
      // icon: 'el-icon-plus',
      code: 'add',
      name: '新增',
    },
    {
      type: 'button',
      buttonType: null,
      // icon: 'el-icon-lock',
      code: 'enable',
      name: '启用',
    },
    {
      type: 'button',
      buttonType: null,
      // icon: 'el-icon-unlock',
      code: 'disable',
      name: '禁用',
    },
    {
      type: 'dropdown',
      buttonType: null,
      // icon: 'el-icon-delete',
      name: '删除',
      code: 'delete',
      props: {},
      children: [
        {
          name: '删除',
        },
      ],
    },
  ],
  columns: [
    // 模板名称 状态 创建日期 创建人 修改日期
    {
      fixed: 'left', // 固定位置在左边
      type: 'checkbox', // 单选
      title: null,
      width: 50,
    },
    {
      field: 'templateName',
      title: '模板名称',
      editView: 'true',
      className: 'formatter-view',
      // cellRender: {
      //   name: 'cellLink',
      //   events: {
      //     click: (btn, row, modalClick) => {
      //       const newBtn = { ...btn, code: 'col-link' };
      //       if (modalClick) {
      //         modalClick(newBtn, row);
      //       }
      //     },
      //   },
      // },
    },
    {
      field: 'dataType',
      title: '类型',
      formatter: ({ cellValue }) => (DataTypeOptions.find((v) => v.value === cellValue) || {}).label,
    },
    {
      field: 'enableStatusName',
      title: '状态',
    },
    {
      field: 'createDateAll',
      title: '创建时间',
    },
    {
      field: 'createName',
      title: '创建人',
    },
    {
      field: 'updateName',
      title: '修改人',
    },
    {
      field: 'updateDateAll',
      title: '修改时间',
    },
    {
      title: '操作',
      width: '80',
      align: 'center',
      buttons: [
        {
          type: 'button',
          buttonType: 'primary',
          icon: 'el-icon-edit',
          code: 'edit',
          name: '编辑',
        },
        {
          type: 'button',
          buttonType: null,
          icon: 'el-icon-tickets',
          code: 'log',
          name: '日志',
        },
        // {
        //   type: 'button',
        //   buttonType: 'primary',
        //   icon: 'el-icon-view',
        //   code: 'view',
        //   name: '查看',
        // },
        // {
        //   type: 'button',
        //   buttonType: 'primary',
        //   icon: 'el-icon-lock',
        //   code: 'row-enable',
        //   name: '启用',
        // },
        // {
        //   type: 'button',
        //   buttonType: 'primary',
        //   icon: 'el-icon-unlock',
        //   code: 'row-disable',
        //   name: '禁用',
        // },
      ],
    },
  ],
  tableConfig: {
    border: true,
    treeConfig: {
      children: 'children',
    },
    idKey: 'id', // 默认勾选判断字段
    resizable: true, // 所有的列是否允许拖动列宽调整大小
    showOverflow: true, // 当内容过长时显示为省略号
    keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
    highlightHoverRow: true, // 鼠标滑动高亮
    // height: '600',
  },
};
